<template>
    <div>
        <div class="row my-2 justify-content-end">
            <div class="col-6">
                <b-button variant="main" @click="() => abrirModal()">Novo</b-button>
            </div>
            <div class="col-6">
                <div class="row justify-content-end">
                    <div class="col-auto">
                        <b-form-select size="sm" v-model="perPage" :options="optionsPerPage" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <b-table ref="mainDataTable" :fields="fields" :current-page="currentPage" :per-page="perPage" :items="provider">
                    <template #cell(acoes)="data">
                        <b-button variant="main" @click="() => abrirModal(data.item.id)" size="sm" class="mr-1">
                            <b-icon icon="pencil" />
                        </b-button>
                        <b-button variant="danger" @click="() => excluindo(data.item.id)" size="sm">
                            <b-icon icon="trash" />
                        </b-button>
                    </template>

                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-auto">
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" />
            </div>
        </div>
        <b-modal v-on:hide="cancelarExclusao" v-model="modalExclusao" header-bg-variant="warning" header-class="text-light">
            <template #modal-title> EXCLUIR ITEM </template>
            <template #default>Deseja confirmar a exclusão do item?</template>
            <template #modal-footer>
                <b-button @click="cancelarExclusao" variant="secondary">Não</b-button>
                <b-button @click="excluir" variant="primary">Sim</b-button>
            </template>
        </b-modal>
        <b-modal size="xl" v-model="showModal" header-bg-variant="primary" header-class="text-light">
            <template #modal-title> {{ newItem ? "CADASTRAR" : "CONSULTAR" }} <slot name="modal-title" /> </template>
            <template #default>
                <slot name="modal-body" />
            </template>
            <template #modal-footer>
                <b-button @click="fecharModal" variant="secondary">Cancelar</b-button>
                <b-button @click="salvar" variant="primary">Salvar</b-button>
            </template>
        </b-modal>
        <b-modal size="md" v-model="showModalErro" header-bg-variant="warning" header-class="text-light">
            <template #modal-title>ERRO AO {{ newItem ? "CADASTRAR" : "ATUALIZAR" }}</template>
            <template #default>
                <div class="row">
                    <div class="col-12" v-for="(erros, campo) in salvarErros" :key="campo">
                        <strong>{{campo.replace('_', ' ').replace('_id', '').toUpperCase()}}</strong>
                        <ul>
                            <li v-for="(erro, i) in erros" :key="i">{{erro}}</li>
                        </ul>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import api from "@/services/api";

    export default {
        name: "Datatable",
        props: ["endpoint"],
        data() {
            return {
                perPage: 10,
                currentPage: 1,
                items: [],
                rows: 0,
                optionsPerPage: [10, 25, 50, 100],
                fields: [],
                modalExclusao: false,
                excluindoId: null,
                salvarErros: [],
                showModalErro: false,
            };
        },
        computed: {
            showModal: {
                get() {
                    return this.$store.state.data_table.showModal;
                },
                set(value) {
                    this.$store.dispatch("setDataTableModal", value);
                    this.$store.dispatch("setDataTableModalItem", {});
                },
            },
            newItem() {
                return !this.$store.state.data_table.item.id;
            },
            item() {
                return this.$store.state.data_table.item;
            },
        },
        methods: {
            provider(context, callback) {
                this.busy = true;
                api.get(this.endpoint, {
                    params: {
                        page: context.currentPage,
                        perPage: context.perPage,
                    },
                }).then(({ data }) => {
                    this.items = data.data;
                    this.rows = data.meta.total;
                    this.fields = data.meta.fields;

                    callback(this.items);
                });
            },
            excluindo(id) {
                this.modalExclusao = true;
                this.excluindoId = id;
            },
            excluir() {
                api.delete(`${this.endpoint}/${this.excluindoId}`).then(() => {
                    this.$refs.mainDataTable.refresh();
                    this.modalExclusao = false;
                });
            },
            cancelarExclusao() {
                (this.excluindoId = null), (this.modalExclusao = false);
            },
            async abrirModal(id = null) {
                this.$store.commit("SET_LOADING_FULL", true);
                let item = {};
                if (id) {
                    await api.get(`${this.endpoint}/${id}`).then(res => (item = res.data.data));
                }
                this.$store.commit("SET_LOADING_FULL", false);
                this.$store.dispatch("setDataTableModal", true);
                this.$store.dispatch("setDataTableModalItem", item);
            },
            fecharModal() {
                this.showModal = false;
                this.currentItemId = null;
            },
            async salvar() {
                this.$store.commit("SET_LOADING_FULL", true)

                let request;

                if (this.newItem) {
                    request = api.post(this.endpoint, this.item);
                } else {
                    request = api.put(`${this.endpoint}/${this.item.id}`, this.item);
                }

                request
                    .then(() => {
                        this.$refs.mainDataTable.refresh();
                        this.showModal = false;
                    })
                    .catch(err => {
                        this.salvarErros = err.response.data.errors;
                        this.showModalErro = true;
                    })
                    .finally(() => {
                        this.$store.commit("SET_LOADING_FULL", false)
                    });
            },
        },
    };
</script>
