<template>
    <div id="app">
        <Header v-if="pageHasHeader" />
        <Navbar v-if="pageHasNavbar" />
        <router-view />
        <Footer />
        <notifications position="bottom right" group="main" />
        <div>
            <b-overlay :show="loadingFull" no-wrap bg-color="rgba(0,0,0,.4)"> </b-overlay>
        </div>
    </div>
</template>

<script>
    import "./css/global.css";

    import Header from "./components/Header.vue";
    import Footer from "./components/Footer.vue";
    import api from "./services/api";
    import Navbar from "./components/Navbar.vue";

    export default {
        name: "App",
        title: "Ancore - Proteção Veicular",
        components: {
            Footer,
            Header,
            Navbar,
        },
        computed: {
            loadingFull() {
                return this.$store.state.loading.full;
            },
            pageHasHeader() {
                return this.$route.meta.header;
            },
            pageHasNavbar() {
                return this.$route.meta.navbar;
            },
            pageTitle() {
                return this.$route.meta.title || null;
            }
        },
        watch: {
            "$route.name": {
                handler: function () {
                    const seletor = document.querySelector("html");
                    seletor.classList.add("overlay-body");
                    this.$store.commit("SET_LOADING_FULL", true);
                    setTimeout(() => {
                        seletor.classList.remove("overlay-body");
                        this.$store.commit("SET_LOADING_FULL", false);
                    }, 1000);
                },
                deep: true,
                immediate: true,
            },
            "$route.params.usuario_hash": {
                handler: function (value) {
                    if (value) {
                        api.defaults.headers.UsuarioHash = value;
                        this.$store.dispatch("setUsuarioCotacao", value).then(() => {
                            let usuarioConfig = this.$store.state.usuario.configuracao;
                            window.fbq("init", usuarioConfig.facebook_pixel_id);
                            window.fbq("track", "PageView");
                            window.GTM(window, document, "script", "dataLayer", usuarioConfig.google_gtm_id);
                        });
                    }
                },
                deep: true,
                immediate: true,
            },
            "$route.query.ref": {
                handler: function (value) {
                    if (value) {
                        api.defaults.headers.Referencia = value;
                    }
                },
                deep: true,
                immediate: true,
            },
        },
    };
</script>

<style>
    .overlay-body {
        position: fixed;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .overlay-body footer {
        position: inherit;
    }
</style>
