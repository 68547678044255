<template>
    <main>
        <div class="container-md">
            <div class="row">
                <div class="col-md-3 col-md-offset-3"></div>
                <div class="col-md-6">
                    <form @submit.prevent="login">
                        <h3 class="mt-4 text-center mb-4">Login</h3>
                        <div class="form-group">
                            <div class="v-input input-field">
                                <fieldset class="fieldset" :class="{ 'error-field': emailState }">
                                    <legend for="email"><span>Email</span></legend>
                                    <b-form-input id="email" v-model="email" placeholder="Informe seu email" v-on:keyup="validInput('email')" />
                                </fieldset>
                            </div>
                            <b-form-invalid-feedback id="email" :force-show="emailState">
                                <div v-for="(erro, index) in emailErros" :key="`error-email-${index}`">
                                    {{ erro }}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                        <div class="form-group">
                            <div class="v-input input-field">
                                <fieldset class="fieldset" :class="{ 'error-field': senhaState }">
                                    <legend for="senha"><span>Senha</span></legend>
                                    <b-form-input id="senha" v-model="senha" type="password" placeholder="Informe sua senha" v-on:keyup="validInput('senha')" />
                                </fieldset>
                            </div>
                            <b-form-invalid-feedback id="senha" :force-show="senhaState">
                                <div v-for="(erro, index) in senhaErros" :key="`error-senha-${index}`">
                                    {{ erro }}
                                </div>
                            </b-form-invalid-feedback>
                        </div>
                        <b-overlay :show="loading">
                            <b-btn class="mt-4" variant="main" size="lg" block type="submit"> FAZER LOGIN </b-btn>
                        </b-overlay>
                        <b-form-invalid-feedback id="login" class="text-center mt-4" :force-show="loginState">
                            <div v-for="(erro, index) in loginErros" :key="`error-login-${index}`">
                                {{ erro }}
                            </div>
                        </b-form-invalid-feedback>
                    </form>
                </div>
                <div class="col-md-3 col-md-offset-3"></div>
            </div>
        </div>
    </main>
</template>

<script>
    import usuario from "../services/usuario";

    export default {
        name: "Cliente",
        data() {
            return {
                emailValidation: true,
                senhaValidation: true,
                email: "",
                senha: "",
                lembrar: 0,
            };
        },
        computed: {
            loading() {
                return this.$store.state.loading.page;
            },
            erros: {
                get() {
                    return this.$store.state.erros;
                },
            },
            emailErros() {
                return this.$store.state.erros.email;
            },
            emailState() {
                return this.emailErros.length > 0 ? true : null;
            },
            senhaErros() {
                return this.$store.state.erros.senha;
            },
            senhaState() {
                return this.senhaErros.length > 0;
            },
            loginErros() {
                return this.$store.state.erros.login;
            },
            loginState() {
                return this.loginErros.length > 0;
            },
        },
        methods: {
            async login() {
                try {
                    let erros = [];
                    if (!this.email) {
                        erros.push({ email: ["O campo Email é obrigatório."] });
                    }
                    if (!this.senha) {
                        erros.push({ senha: ["O campo Senha é obrigatório"] });
                    }

                    if (!erros.length) {
                        this.$store.commit("SET_LOADING_FULL", true);
                        await usuario.login({ email: this.email, senha: this.senha, lembrar: this.lembrar }).then(res => {
                            this.$router.push({ name: "planos" });
                            this.$store.dispatch("setUsuario", res.data);
                        });
                    } else {
                        this.$store.dispatch(
                            "setErrors",
                            erros.reduce((a, b) => Object.assign(a, b), {})
                        );
                    }
                } catch (error) {
                    console.log(typeof error.response.data.errors);
                    this.$store.dispatch(
                        "setErrors",
                        error.response.data.errors.reduce((a, b) => Object.assign(a, b), {})
                    );
                } finally {
                    this.$store.commit("SET_LOADING_FULL", false);
                }
            },
            validInput(name) {
                if (name == "email") {
                    if (this.$store.state.cotacao.email != "") {
                        this.$store.state.erros.email = {};
                    }
                }

                if (name == "senha") {
                    if (this.$store.state.cotacao.telefone != "") {
                        this.$store.state.erros.telefone = {};
                    }
                }
            },
        },
    };
</script>
