<template>
    <main>
        <div class="box-area">
            <div class="box-single d-flex align-items-center bg-box">
                <div class="container box-container">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-6 col-md-5"></div>
                        <div class="col-lg-8">
                            <div class="box-form">
                                <div class="slider-text text-center">
                                    <b-icon icon="check2-circle" variant="success" font-scale="5" class="mb-4"></b-icon>
                                    <h3 class="title">Obrigado</h3>
                                    <h6 class="mt-4 mb-4">Um consultor entrará em contato para assinatura dos termos de adesão e agendamento da vistoria.</h6>
                                    <div class="hr-footer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
    export default {
        name: "Fechamento",
        data() {
            return {};
        },
        mounted() {
            const btnVoltar = document.querySelector(".btn-voltar");
            btnVoltar.style.display = "none";
        },
    };
</script>

<style scoped>
    h4 {
        margin: 0;
    }

    .box-form {
        background: #fff;
        border-radius: 4px;
        padding: 30px 20px;
        border: 1px solid #d8e2ef;
        position: relative;
        top: 30px;
    }
</style>
