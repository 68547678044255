<template>
    <main>
        <div class="container">
            <Datatable endpoint="/veiculo-especial">
                <template #modal-title>VEÍCULO ESPECIAL</template>
                <template #modal-body>
                    <div class="row">
                        <div class="col-4">
                            <label for="veiculo_tipo_id">Tipo de Veículo</label>
                            <b-form-select value-field="id" text-field="descricao" v-model="item.veiculo_tipo_id" :options="tipos"></b-form-select>
                        </div>
                        <div class="col-4">
                            <label for="nome">Marca</label>
                            <b-form-select value-field="id" text-field="name" v-model="item.marca_id" :options="marcas"></b-form-select>
                        </div>
                        <div class="col-4">
                            <label for="nome">Modelo</label>
                            <b-form-select value-field="id" text-field="name" v-model="item.modelo_id" :options="modelos"></b-form-select>
                        </div>
                        <div class="col-4">
                            <label for="min">Valor Mínimo</label>
                            <b-form-input type="number" v-model="item.minimo" id="minimo"/>
                        </div>
                    </div>
                </template>
            </Datatable>
        </div>
    </main>
</template>
<script>
    import Datatable from "@/components/Datatable.vue";
    import api from "@/services/api";
    import fipe from "@/services/fipe";

    export default {
        name: "VeiculosEspeciais",
        components: { Datatable },
        data() {
            return {
                tipos: [],
                marcas: [],
                modelos: [],
            };
        },
        computed: {
            item: {
                get() {
                    return this.$store.state.data_table.item;
                },
                set(value) {
                    this.$store.dispatch("setDataTableModalItem", value);
                },
            },
        },
        beforeMount() {
            api.get("veiculo-tipo").then(res => (this.tipos = res.data));
        },
        watch: {
            "item.veiculo_tipo_id": {
                handler: function (value) {
                    if (value) {
                        fipe.getMarcas(value).then(res => (this.marcas = res.data.data));
                    }
                },
                deep: true,
                immediate: true,
            },
            "item.marca_id": {
                handler: function (value) {
                    if (value) {
                        fipe.getModelos(this.item.veiculo_tipo_id, value).then(res => (this.modelos = [{ id: null, name: "TODOS" }, ...res.data.data]));
                    }
                },
                deep: true,
                immediate: true,
            },
        },
    };
</script>
