<template>
    <main>
        <div class="container">
            <Datatable endpoint="/plano">
                <template #modal-title> PLANO </template>
                <template #modal-body>
                    <div class="row">
                        <div class="col-4">
                            <label for="nome">Descrição</label>
                            <b-input type="text" id="descricao" v-model="item.descricao" />
                        </div>
                        <div class="col-4">
                            <label for="veiculo_tipo_id">Tipo de Veículo</label>
                            <b-form-select value-field="id" text-field="descricao" v-model="item.veiculo_tipo_id" :options="tipos"></b-form-select>
                        </div>
                        <div class="col-4">
                            <label for="especial">Especial?</label>
                            <b-form-checkbox :value="1" :unchecked-value="0" v-model="item.especial"/>
                        </div>
                        <div class="col-12">
                            <div class="row mt-2">
                                <div :class="`col-${estado.id ? '3' : '12'}`" v-for="estado in estados" :key="estado.id">
                                    <b-form-checkbox :value="estado.id" v-model="item.estados">{{estado.nome}}</b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </Datatable>
        </div>
    </main>
</template>
<script>
    import Datatable from "@/components/Datatable.vue";
    import api from "@/services/api";
    export default {
        name: "Planos",
        components: { Datatable },
        data() {
            return {
                tipos: [],
                estados: [],
            };
        },
        computed: {
            showModal: {
                get() {
                    return this.$store.state.data_table.showModal;
                },
                set(value) {
                    this.$store.dispatch("setDataTableModal", value);
                },
            },
            item: {
                get() {
                    return this.$store.state.data_table.item;
                },
                set(value) {
                    this.$store.dispatch("setDataTableModalItem", value);
                },
            },
        },
        beforeMount() {
            api.get("veiculo-tipo").then(res => (this.tipos = res.data));
            api.get("estados").then(res => (this.estados = [{id: null, nome: "Geral"}, ...res.data]));
        },
    };
</script>
