<template>
    <main>
        <div class="container">
            <Datatable endpoint="/usuario">
                <template #modal-title>USUÁRIO</template>
                <template #modal-body>
                    <div class="row">
                        <div class="col-4">
                            <label for="nome">Nome</label>
                            <b-form-input type="text" id="nome" v-model="item.nome"/>
                        </div>
                        <div class="col-4">
                            <label for="email">Email</label>
                            <b-form-input type="text" id="email" v-model="item.email"/>
                        </div>
                        <div class="col-4">
                            <label for="hash">Identificador</label>
                            <b-form-input type="text" id="hash" v-model="item.hash" disabled/>
                        </div>
                        <div class="col-4">
                            <label for="senha">Senha</label>
                            <b-form-input v-model="item.senha" id="senha" type="password"/>
                        </div>
                        <div class="col-4">
                            <label for="senha_confirmation">Confirmar Senha</label>
                            <b-form-input v-model="item.senha_confirmation" id="senha_confirmation" type="password"/>
                        </div>
                    </div>
                </template>

            </Datatable>
        </div>
    </main>
</template>
<script>
    import Datatable from "@/components/Datatable.vue";
    export default {
        name: "Usuarios",
        components: { Datatable },
        computed: {
            item: {
                get() {
                    return this.$store.state.data_table.item;
                },
                set(value) {
                    this.$store.dispatch("setDataTableModalItem", value);
                },
            },
        },
    };
</script>
