<template>
    <div class="form-group">
        <div class="v-input">
            <fieldset class="fieldset" :class="{ 'error-field': invalid }">
                <legend for="cilindrada"><span>Cilindrada</span></legend>
                <div class="v-select">
                    <v-select
                        v-model="cilindrada"
                        id="cilindrada"
                        :clearable="true"
                        placeholder="Selecione uma Cilindrada"
                        :options="cilindradas"
                    >
                        <div slot="no-options">Cilindrada não encontrado</div>
                    </v-select>
                </div>
            </fieldset>
        </div>
        <b-form-invalid-feedback :force-show="invalid">
            <div v-for="(erro, index) in erros" :key="`error-ano-${index}`">
                {{ erro }}
            </div>
        </b-form-invalid-feedback>
    </div>
</template>

<script>
    export default {
        name: "Cilindrada",
        computed: {
            cilindradas() {
                return this.$store.state.cilindradas;
            },
            erros() {
                return this.$store.state.erros.cilindrada;
            },
            invalid() {
                return this.erros.length > 0;
            },
            cilindrada: {
                get() {
                    return this.$store.state.cotacao.cilindrada;
                },
                set(value) {
                    return this.$store.commit("SET_COTACAO_CILINDRADA", value);
                },
            },
        },
        mounted() {
            if (localStorage.getItem("cilindrada")) {
                this.$store.commit("SET_COTACAO_CILINDRADA", Number(localStorage.getItem("cilindrada")));
            }
        },
        watch: {
            estado(newCilindrada) {
                if (newCilindrada) {
                    localStorage.setItem("cilindrada", newCilindrada);
                } else {
                    localStorage.removeItem("cilindrada");
                }
            },
        },
    };
</script>
