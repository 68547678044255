<template>
    <main>
        <div class="container">
            <Datatable endpoint="/opcional">
                <template #modal-title>OPCIONAL</template>
                <template #modal-body>
                    <div class="row">
                        <div class="col-4">
                            <label for="estado_id">Plano - Estado</label>
                            <b-form-select plain v-model="item.plano_estado_id">
                                <option v-for="planoEstado in plano_estados" :value="planoEstado.id" :key="planoEstado.id">
                                    {{`${planoEstado.plano.descricao} - ${planoEstado.estado?.nome || 'Geral'}`}}
                                </option>
                            </b-form-select>
                        </div>
                        <div class="col-4">
                            <label for="opcional_tipo_id">Tipo de Opcional</label>
                            <b-form-select text-field="nome" value-field="id" v-model="item.opcional_tipo_id" :options="opcional_tipos"/>
                        </div>
                        <div class="col-4">
                            <label for="valor">Valor</label>
                            
                            <money
                                v-model="item.valor"
                                v-bind="{ precision: 2, masked: false }"
                                id="valor"
                                placeholder="0,00"
                                class="form-control"
                                type="text"
                            ></money>
                        </div>
                    </div>
                </template>
            </Datatable>
        </div>
    </main>
</template>
<script>
    import Datatable from "@/components/Datatable.vue";
import api from "@/services/api";
    export default {
        name: "Opcionais",
        components: { Datatable },
        data() {
            return {
                plano_estados: [],
                opcional_tipos: [],
            };
        },
        computed: {
            item: {
                get() {
                    return this.$store.state.data_table.item;
                },
                set(value) {
                    this.$store.dispatch("setDataTableModalItem", value);
                },
            },
        },
        beforeMount() {
            api.get("plano-estado").then(res => (this.plano_estados = res.data));
            api.get("opcional-tipo").then(res => (this.opcional_tipos = res.data));
        },
    };
</script>
